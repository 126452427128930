<template>
  <section>
    <div class="card card-body">
      <form @submit.prevent="filterTags">
        <div class="theme-filter-nav row">
          <div class="col-6">
            <Input v-model="themeFilter.name" id="filter_title" :label="$t('themeSpecialAndSeries.name')" />
          </div>
          <!-- TMP disable
            <div class="col-lg-2 col-md-6">
              <Input v-model="themeFilter.id" id="filter_id" :label="$t('tag.filter.id')" />
            </div>
          -->
          <div class="col-2">
            <SelectInput
              v-model="themeFilter.isActive"
              id="theme_isActive_filter_select"
              optionTitle="title"
              :options="isActiveSettings"
              :required="false"
              :label="$t('themeSpecialAndSeries.isActive')"
            />
          </div>
          <div class="col-4">
            <button class="btn btn-success pull-right">
              {{ $t('tag.filter.search') }}
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-test="reset_filter"
              @click="resetFilter"
            >
              {{ $t('buttons.reset_filter') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import Input from '@/components/form/inputs/Input'
import ThemeFilter from '@/model/ThemeFilter'
import SelectInput from '@/components/form/select/Select.vue'

export default {
  name: 'SpecialFilter',
  components: {
    SelectInput,
    Input
  },
  data () {
    return {
      isLoading: false,
      themeFilter: this._.cloneDeep(ThemeFilter),
      isActiveSettings: [
        {
          title: this.$t('themeSpecialAndSeries.select_isActive'),
          id: 'true'
        },
        {
          title: this.$t('themeSpecialAndSeries.select_isInactive'),
          id: 'false'
        }
      ]
    }
  },
  methods: {
    getTheme () {
      this.$store.commit('themeSpecialAndSeries/SET_THEME_FILTER', this.themeFilter)
      this.$store.dispatch('themeSpecialAndSeries/fetchThemeList')
    },
    filterTags () {
      this.$store.commit('themeSpecialAndSeries/SET_THEME_PAGE', 1)
      this.getTheme()
    },
    resetFilter () {
      this.themeFilter = this._.cloneDeep(ThemeFilter)
      this.getTheme()
    }
  },
  beforeMount () {
    this.resetFilter()
  }
}
</script>

<style lang="scss">

.theme-filter-nav {
  align-items: center;
}

</style>
