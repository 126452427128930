<template>
  <app-draggable tag="ul" :list="relatedArticles" class="dragArea list-icons">
    <div v-for="(relatedArticle, index) in relatedArticles" :key="`item-${index}`" class="m-b-5">
      <button
        v-if="showRemoveButton"
        class="btn btn-xs btn-danger"
        @click="removeRelatedArticle(relatedArticle)"
      >
        <span class="fa fa-times"></span>
      </button>
      <span class="label related-article-title p-2">{{ relatedArticle.field.title | truncate }}</span>
      <router-link
        tag="a"
        class="btn btn-default btn-sm"
        target="_blank"
        :title="relatedArticle.documentId"
        :to="{ name: 'article_detail', params: { id: relatedArticle.id }}"
      >
        <i class="fa fa-info"></i>
      </router-link>
      <app-article-status
        :article="relatedArticle"
      >
      </app-article-status>
    </div>
  </app-draggable>
</template>

<script>
import Draggable from 'vuedraggable'
import ArticleStatus from './ArticleStatus'

export default {
  name: 'RelatedArticleList',
  props: {
    showRemoveButton: {
      type: Boolean,
      default: true
    },
    relatedArticlesProp: {
      type: Array,
      default: () => []
    }
  },
  components: {
    appDraggable: Draggable,
    appArticleStatus: ArticleStatus
  },
  computed: {
    relatedArticles () {
      if (!this.relatedArticlesProp) {
        return this.$store.getters['article/relatedArticles']
      } else return this.relatedArticlesProp
    }
  },
  methods: {
    removeRelatedArticle (article) {
      if (!this.relatedArticlesProp) {
        this.$store.commit('article/removeRelatedArticle', article)
      } else this.$emit('removeRelatedArticle', article)
    }
  }
}
</script>

<style scoped lang="scss">
  .related-article-title {
    font-size: 14px;
    cursor: move;
    background-color: #f6f6f6;
    color: #2f3d4a;
    width: 70%;
    /*
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    */
    border: 0;
  }
  .related-article-title:hover {
    background-color: #d6d6d6;
  }
</style>
